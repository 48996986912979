// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox='0 0 400 400' {...props}>
    <path d="M335.4 176.326L276.236 199.999H186.509L182.364 233.162L247.6 363.635H358.8L335.4 176.326ZM330.727 139.035L326.982 109.09H197.873L191.055 163.635H269.236L330.727 139.035ZM370.255 161.981L370.382 162.344L370.291 162.381L400 399.999H0L40.9091 72.7261H359.091L370.255 161.999V161.981ZM54.8364 254.544L41.2 363.635H206.945L152.4 254.544H54.8364ZM59.3818 218.181H147.582L161.218 109.09H73.0182L59.3818 218.181Z" fill="#1E32E5" />
    <path d="M109.091 127.273C72.7272 97.8727 54.5454 73.3273 54.5454 53.5818C54.5454 24 78.9636 0 109.091 0C139.218 0 163.636 24 163.636 53.5818C163.636 73.3273 145.454 97.8727 109.091 127.273ZM110.073 72.7273C120.291 72.7273 128.564 64.5818 128.564 54.5455C128.564 44.5091 120.291 36.3636 110.073 36.3636C99.8363 36.3636 91.5636 44.5091 91.5636 54.5455C91.5636 64.5818 99.8363 72.7273 110.073 72.7273Z" fill="#1E32E5" />
  </Svg>
);

export default Icon;
