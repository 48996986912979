// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox='0 0 16 14' {...props}>
    <path opacity="0.9" fillRule="evenodd" clipRule="evenodd" d="M14.371 3.67643C14.371 8.03851 11.0696 13.0667 5.03181 13.0667C3.24242 13.0657 1.49273 12.5503 0 11.5843C0.259847 11.6128 0.521027 11.6281 0.782489 11.6301C2.26747 11.6301 3.7072 11.1297 4.8597 10.213C3.44033 10.1788 2.20334 9.25748 1.79112 7.9275C1.995 7.96696 2.20235 7.98664 2.41017 7.98627C2.70346 7.98729 2.99541 7.94772 3.27738 7.86873C1.72728 7.53905 0.626797 6.18907 0.642402 4.63635C1.09834 4.84743 1.60835 4.98841 2.13067 5.00856C0.683352 4.01439 0.243704 2.10698 1.1147 0.600766C2.75677 2.65095 5.2228 3.90769 7.88026 4.04864C7.8237 3.80217 7.79528 3.5503 7.79554 3.29768C7.77181 1.4997 9.24151 0.0232913 11.0783 0C11.9903 0.000418626 12.8599 0.377415 13.4731 1.03828C14.2111 0.895746 14.9174 0.625999 15.5591 0.241613C15.3189 1.00025 14.8054 1.64769 14.1142 2.0635C14.766 1.9871 15.4021 1.81311 16 1.54763C15.5617 2.20611 15.0074 2.78318 14.363 3.25197C14.369 3.39564 14.371 3.5393 14.371 3.67643Z" fill="white" />
  </Svg>
);

export default Icon;
