// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox='0 0 16 19' {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.8 14.3632L16 18.3529V1.99488C16 0.89314 15.1046 0 14 0H2C0.89543 0 0 0.89314 0 1.99488V13.9642C0 15.0659 0.89543 15.9591 2 15.9591H12.304L11.8 14.3632ZM4.352 5.54578C4.352 5.54578 5.856 4.62813 8 4.62813C10.144 4.62813 11.648 5.54578 11.648 5.54578C10.824 4.81166 9.384 4.38875 9.384 4.38875L9.52 4.25309C10.872 4.27703 12.096 5.21064 12.096 5.21064C13.472 8.07529 13.384 10.549 13.384 10.549C12.264 11.9932 10.6 11.8895 10.6 11.8895L10.032 11.1714C11.032 10.9559 11.664 10.0702 11.664 10.0702C11.664 10.0702 10.16 11.0916 8 11.0916C5.84 11.0916 4.336 10.0702 4.336 10.0702C4.336 10.0702 4.968 10.9559 5.968 11.1714L5.4 11.8895C5.4 11.8895 3.736 11.9932 2.616 10.549C2.616 10.549 2.528 8.07529 3.904 5.21064C3.904 5.21064 5.128 4.27703 6.48 4.25309L6.616 4.38875C6.616 4.38875 5.176 4.81166 4.352 5.54578ZM6.344 7.65238C6.864 7.65238 7.288 8.10721 7.28 8.66578C7.28 9.21637 6.864 9.67918 6.344 9.67918C5.832 9.67918 5.416 9.21637 5.416 8.66578C5.416 8.10721 5.824 7.65238 6.344 7.65238ZM10.616 8.66578C10.616 8.10721 10.2 7.65238 9.68 7.65238C9.16 7.65238 8.752 8.10721 8.752 8.66578C8.752 9.21637 9.168 9.67918 9.68 9.67918C10.2 9.67918 10.616 9.21637 10.616 8.66578Z" fill="white"/>
  </Svg>
);

export default Icon;
