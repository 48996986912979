// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox='0 0 400 400' {...props}>
    <path d="M257.812 259.375V258.553C257.812 232.825 240.984 209.531 216.894 201.909L198.716 196.159C206.722 183.235 210.955 168.329 210.938 153.125V141.406H257.812V129.688C257.812 101.391 237.65 77.7188 210.938 72.2719V35.1562C210.938 15.7719 195.166 0 175.781 0H82.0312C62.6469 0 46.875 15.7719 46.875 35.1562V72.2719C20.1625 77.7188 0 101.391 0 129.688V141.406H46.875V153.125C46.8574 168.328 51.0907 183.232 59.0969 196.156L40.9219 201.906C16.8281 209.531 0 232.828 0 258.556V400H400V259.375H257.812ZM70.3125 35.1562C70.3158 32.0493 71.5515 29.0705 73.7485 26.8735C75.9455 24.6765 78.9243 23.4408 82.0312 23.4375H175.781C178.888 23.4408 181.867 24.6765 184.064 26.8735C186.261 29.0705 187.497 32.0493 187.5 35.1562V71.0938H70.3125V35.1562ZM25.4438 117.969C30.2812 104.328 43.3156 94.5312 58.5938 94.5312H199.219C214.497 94.5312 227.531 104.328 232.369 117.969H25.4438ZM128.906 211.719C96.5969 211.719 70.3125 185.434 70.3125 153.125V141.406H187.5V153.125C187.5 185.434 161.216 211.719 128.906 211.719ZM140.625 234.316V247.656C140.622 250.763 139.386 253.742 137.189 255.939C134.992 258.136 132.013 259.372 128.906 259.375C125.799 259.372 122.82 258.136 120.623 255.939C118.427 253.742 117.191 250.763 117.188 247.656V234.316C124.96 235.433 132.852 235.433 140.625 234.316ZM164.062 376.562V353.125H175.781C191.059 353.125 204.094 362.922 208.931 376.562H164.062ZM210.938 341.438C200.81 333.8 188.466 329.674 175.781 329.688H93.75V294.531H70.3125V353.125H140.625V376.562H23.4375V258.553C23.4375 242.934 33.5312 228.828 47.9906 224.253L75.6844 215.494C81.1658 220.181 87.2397 224.127 93.75 227.231V247.656C93.75 267.041 109.522 282.812 128.906 282.812C148.291 282.812 164.062 267.041 164.062 247.656V227.231C170.573 224.128 176.647 220.181 182.128 215.494L209.822 224.253C224.281 228.828 234.375 242.931 234.375 258.553V259.375H210.938V341.438ZM376.562 376.562H234.375V282.812H376.562V376.562ZM281.25 306.25V329.688H329.688V306.25H281.25Z" fill="#1E32E5" />
  </Svg>
);

export default Icon;
