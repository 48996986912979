// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox='0 0 16 13' {...props}>
    <path d="M1.89733 2.59915C1.91728 2.40267 1.84223 2.20847 1.69533 2.07648L0.202 0.276479V0.0078125H4.84L8.42533 7.87115L11.5773 0.0078125H16V0.276479L14.722 1.50115C14.6119 1.58518 14.5573 1.72319 14.58 1.85981V10.8598C14.5575 10.9962 14.6121 11.1339 14.722 11.2178L15.9687 12.4425V12.7111H9.69533V12.4425L10.9867 11.1878C11.1133 11.0611 11.1133 11.0238 11.1133 10.8291V3.55515L7.52 12.6805H7.03533L2.852 3.55515V9.67115C2.81733 9.92848 2.90267 10.1871 3.08333 10.3731L4.76333 12.4131V12.6811H0V12.4125L1.68 10.3725C1.86 10.1871 1.94 9.92581 1.89733 9.67115V2.59915Z" fill="white"/>
  </Svg>
);

export default Icon;
